import React from 'react';
import styled from 'styled-components';
import {Button, Input, message, Select, Steps, Result} from 'antd';
import * as AppContext from '../AppContext';
import * as Validate from '../domain/Validate';
import ModalContent from './ModalContent';
import TextInput from './TextInput';
import {isMobileNumber} from '../domain/FormatValidator';
import {isEmail} from '../domain/FormatValidator';

const COOL_DOWN_TIME = 120;

const UI_STATE = {
  INPUT_PHONE: {
    key: 0,
    title: '輸入手機',
  },
  INPUT_CODE: {
    key: 1,
    title: '輸入驗證碼',
  },
  INPUT_PASSWORD: {
    key: 2,
    title: '輸入密碼',
  },
  SUCCESS: {
    key: 3,
    title: '完成註冊',
  },
};

const VERIFY_PHONE_STEPS = [
  UI_STATE.INPUT_PHONE,
  UI_STATE.INPUT_CODE,
  UI_STATE.INPUT_PASSWORD,
  UI_STATE.SUCCESS,
];

function RegisterModal(props) {
  const {onSuccess, defaultProfile = '', successResultText = ''} = props;
  const [countdown, setCountdown] = React.useState(null);
  let timer = React.useRef();
  const [uiState, setUiState] = React.useState(UI_STATE.INPUT_PHONE);
  const [registeredProfile, setRegisteredProfile] = React.useState(null);
  const [values, setValues] = React.useState({
    phone: defaultProfile.phone,
    email: defaultProfile.email,
    password: '',
    password2: '',
    vid: '',
    code: '',
    accessToken: '',
  });

  React.useEffect(() => {
    if (countdown === null) {
      return;
    }
    timer = setTimeout(() => {
      if (countdown <= 0) {
        clearInterval(timer);
        setCountdown(null);
      } else {
        setCountdown(countdown - 1);
      }
    }, 1000);
  }, [countdown]);

  const _resetTimer = React.useCallback(() => {
    if (timer) {
      clearInterval(timer);
    }

    setCountdown(COOL_DOWN_TIME);

    return () => {
      timer && clearInterval(timer);
    };
  }, []);

  const content = React.useMemo(() => {
    let _content = null;
    switch (uiState.key) {
      case 0:
        _content = (
          <InputPhoneContent
            values={values}
            setValues={setValues}
            resetTimer={_resetTimer}
            countdown={countdown}
            onNext={() => setUiState(UI_STATE.INPUT_CODE)}
          />
        );
        break;
      case 1:
        _content = (
          <InputCodeContent
            values={values}
            setValues={setValues}
            resetTimer={_resetTimer}
            countdown={countdown}
            onBack={() => {
              setUiState(UI_STATE.INPUT_PHONE);
              clearInterval(timer);
              setCountdown(null);
            }}
            onNext={() => setUiState(UI_STATE.INPUT_PASSWORD)}
          />
        );
        break;

      case 2:
        _content = (
          <InputRegisterDataContent
            values={values}
            setValues={setValues}
            onNext={(_profile) => {
              setUiState(UI_STATE.SUCCESS);
              setRegisteredProfile(_profile);
            }}
          />
        );
        break;

      case 3:
        _content = (
          <SuccessContent
            values={values}
            onNext={() => onSuccess(registeredProfile)}
            successResultText={successResultText}
          />
        );
        break;
      default:
        _content = null;
        break;
    }
    return _content;
  }, [uiState, values, onSuccess, countdown, _resetTimer]);

  return (
    <ModalContent title={`${'驗證及註冊'}`}>
      <Wrapper>
        <Steps
          className="rev-steps"
          current={uiState.key}
          style={{marginBottom: 30}}>
          {VERIFY_PHONE_STEPS.map((step) => (
            <Steps.Step key={step.key} title={step.title} />
          ))}
        </Steps>

        {content}
      </Wrapper>
    </ModalContent>
  );
}

function InputPhoneContent(props) {
  const {values, setValues, onNext, resetTimer, countdown} = props;
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const app = React.useContext(AppContext.Context);

  const _onNext = React.useCallback(async () => {
    setError('');
    if (!isMobileNumber(values.phone)) {
      setError('請確認手機號碼格式是否正確');
      return;
    }

    try {
      setLoading(true);
      let resp = await app.actions.preRegisterPhoneValidate(values.phone);
      resetTimer();
      setValues({...values, vid: resp.vid});
      setLoading(false);
      onNext();
    } catch (err) {
      let errorStr = '';
      if (err.error === 'invalid_phone_number') {
        errorStr = '請確認手機號碼格式是否正確';
      } else if (err.error === 'user_exist_error') {
        errorStr = '此手機號碼已經註冊囉！';
      } else if (err.error === 'too_many_requests') {
        errorStr = '請稍候再嘗試';
      } else {
        errorStr = '無法發送驗證碼！請聯繫客服';
      }
      setError(errorStr);
      setLoading(false);
    }
  }, [onNext, values]);

  return (
    <div className="content">
      <div className="item">
        <label>手機號碼</label>
        <TextInput
          placeholder="0912345678"
          maxLength={10}
          value={values.phone}
          onChange={(e) => {
            setError('');
            setValues({...values, phone: e.target.value});
          }}
        />
        {error && <div className="error">{error}</div>}
      </div>

      <div className="actions-footer">
        <Button
          type="primary"
          onClick={_onNext}
          loading={loading}
          disabled={countdown > 0}>
          取得驗證碼{`${countdown > 0 ? ` (${countdown})` : ''}`}
        </Button>
      </div>
    </div>
  );
}

function InputCodeContent(props) {
  const {values, setValues, onNext, onBack, resetTimer, countdown} = props;
  const app = React.useContext(AppContext.Context);
  const [resendingOtp, setResendingOtp] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const _onNext = React.useCallback(async () => {
    setError('');
    if (!values.code) {
      setError('請輸入驗證碼！');
      return;
    }
    try {
      setLoading(true);
      let resp = await app.actions.verifyOtp({
        vid: values.vid,
        code: values.code,
      });
      setValues({...values, accessToken: resp.access_token});
      setLoading(false);
      onNext();
    } catch (err) {
      let errorStr = '';
      if (err.error === 'otp_validation_error') {
        if (err.detail?.error === 'validation_expired') {
          errorStr = '驗證碼已過期，請重新寄送';
        } else {
          errorStr = '請輸入正確的驗證碼';
        }
      } else if ((err, error === 'invalid_code_length')) {
        errorStr = '請輸入正確的驗證碼';
      } else {
        errorStr = '驗證失敗！請聯繫客服'; // FIXME: 客服聯繫方式？
      }
      setError(errorStr);
      setLoading(false);
    }
  }, [values, app.actions]);

  const _onResendOtp = React.useCallback(async () => {
    setError('');
    try {
      setResendingOtp(true);
      let resp = await app.actions.preRegisterPhoneValidate(values.phone);
      resetTimer();
      setValues({...values, vid: resp.vid});
      setResendingOtp(false);
    } catch (err) {
      let errorStr = '';
      if (err.error === 'invalid_phone_number') {
        errorStr = '請確認手機號碼格式是否正確';
      } else if (err.error === 'user_exist_error') {
        errorStr = '此手機號碼已經註冊囉！';
      } else if (err.error === 'too_many_requests') {
        errorStr = '請稍候再嘗試';
      } else {
        errorStr = '無法發送驗證碼！請聯繫客服';
      }
      setError(errorStr);
      setResendingOtp(false);
    }
  }, [values, resetTimer]);
  return (
    <div className="content">
      <p>驗證碼已寄送至：{values.phone}。</p>
      <div className="item">
        <label>驗證碼</label>
        <TextInput
          placeholder="123456"
          maxLength={6}
          value={values.code}
          onChange={(e) => {
            setError('');
            setValues({...values, code: e.target.value});
          }}
        />
        {error && <div className="error">{error}</div>}
      </div>

      <div className="actions-footer">
        <Button style={{marginRight: 10}} onClick={onBack}>
          上一步
        </Button>
        <Button
          style={{marginRight: 10}}
          onClick={_onResendOtp}
          loading={resendingOtp}
          disabled={countdown > 0 || resendingOtp}>
          重新寄送{`${countdown > 0 ? ` (${countdown})` : ''}`}
        </Button>
        <Button
          type={'primary'}
          loading={loading}
          disabled={loading}
          onClick={_onNext}>
          驗證
        </Button>
      </div>
    </div>
  );
}

function InputRegisterDataContent(props) {
  const {values, setValues, onNext} = props;
  const app = React.useContext(AppContext.Context);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const _onNext = React.useCallback(async () => {
    setError('');
    if (!values.password || !values.password2 || !values.email) {
      setError('請確認所有欄位都已填寫！');
      return;
    }
    if (values.password !== values.password2) {
      setError('確認密碼不一致！');
      return;
    }

    if (values.password.length < 8) {
      setError('密碼至少須為八碼！');
      return;
    }

    if (!isEmail(values.email)) {
      setError('請確認電子信箱格式！');
      return;
    }

    try {
      setLoading(true);
      let resp = await app.actions.register({
        password: values.password,
        accessToken: values.accessToken,
        email: values.email,
      });

      let userProfile = await app.actions.login({
        username: resp.username,
        password: values.password,
      });
      setLoading(false);
      onNext(userProfile);
    } catch (err) {
      let errorStr = '';
      if (err.error === 'invalid_password') {
        errorStr = '密碼須為八碼以上！';
      } else if (err.error === 'invalid_email_schema') {
        errorStr = '請輸入正確的電子信箱';
      } else {
        errorStr = '註冊失敗！請聯繫客服'; // FIXME: 客服聯繫方式？
      }
      setError(errorStr);
      setLoading(false);
    }
  }, [values, app.actions]);

  return (
    <div className="content">
      <div className="item" style={{marginBottom: 10}}>
        <label>電子信箱</label>
        <TextInput
          value={values.email}
          onChange={(e) => {
            setError('');
            setValues({...values, email: e.target.value});
          }}
        />
        <span style={{color: 'grey', fontSize: 14, marginTop: 5}}>
          *
          訂單成立及電子發票是以email通知，請務必填入正確的email帳號，以免未收到訂單資訊。
        </span>
      </div>
      <div className="item" style={{marginBottom: 10}}>
        <label>
          密碼
          <span style={{color: 'grey', fontSize: 14, marginLeft: 5}}>
            (請輸入八碼以上的任意字元)
          </span>
        </label>
        <TextInput
          type="password"
          value={values.password}
          onChange={(e) => {
            setError('');
            setValues({...values, password: e.target.value});
          }}
        />
      </div>
      <div className="item">
        <label>確認密碼</label>
        <TextInput
          type="password"
          value={values.password2}
          onChange={(e) => {
            setError('');
            setValues({...values, password2: e.target.value});
          }}
        />
      </div>

      {error && <div className="error">{error}</div>}

      <div className="actions-footer">
        <Button
          type="primary"
          loading={loading}
          disabled={loading}
          onClick={_onNext}>
          註冊
        </Button>
      </div>
      <div className="hint">註冊時，同步接受隱私權政策與使用條款</div>
    </div>
  );
}

function SuccessContent(props) {
  const {values, onNext, successResultText} = props;
  return (
    <div className="content">
      <Result
        title="註冊完成"
        subTitle={successResultText || '已完成註冊，請繼續付款流程'}
        status="success"
      />

      <div className="actions-footer">
        <Button type="primary" onClick={onNext}>
          關閉視窗
        </Button>
      </div>
    </div>
  );
}

const Wrapper = styled.div`
  padding: 10px 20px 30px 20px;
  & > .content {
    & .error {
      color: #ff4d4f;
      font-size: 14px;
      text-align: right;
    }

    & > p {
      font-size: 14px;
      margin-bottom: 20px;
    }
    & > .item {
      & > label {
        font-size: 14px;
        margin-bottom: 10px;
      }
    }

    & > .actions-footer {
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;
    }

    & > .hint {
      font-size: 14px;
      color: grey;
      margin-top: 5px;
      text-align: right;
    }
  }
`;

export default RegisterModal;
