import React, {useEffect, useRef} from 'react';
import {navigate} from 'gatsby';
import * as AppContext from '../../AppContext';
import * as AppActions from '../../AppActions';
import * as Ant from 'antd';
import styled from 'styled-components';
import Button from '../../components/Button';
import URL_PATH from '../../UrlPath';
import Cart from '../../components/Cart';
import queryParams from '../../utils/queryParams';
import {
  DeliveryForm,
  InvoiceForm,
  OrderNoteForm,
  PaymentForm,
  TotalItemAmountForm,
  UserInfoForm,
} from '../../components/CartCofigForms';
import {
  BREAK_POINTS,
  INVALID_CART_DATA_ERROR_KEYS,
  INVALID_CART_ERROR_KEYS,
  INVALID_RECEIVER_ADDRESS_CITY,
  INVOICE_SUBTYPE,
  INVOICE_TYPE,
  LOGISTICE_TYPE,
} from '../../domain/Constants';
import {
  isChineseName,
  isCompanyId,
  isEmail,
  isMobileNumber,
  isMobileVehicleCode,
  isNotEmpty,
  isPersonCertificatedCode,
} from '../../domain/FormatValidator';
import PhoneVerifyModal from '../../components/PhoneVerifyModal';
import RegisterModal from '../../components/RegisterModal';
import {
  PRODUCT_BUY_LIMIT,
  PRODUCT_ALLOW_BUY_TIME,
} from '../../domain/ProductHardcodeConstraint';
import FixedRatioImage from '../../components/FixedRatioImage';
import moment from 'moment';
import Gtag, {useViewed} from '../../utils/gtag';

const steps = ['購物車', '訂購資訊', '前往付款'];

const UiState = {
  cartItems: 'cartItems',
  cartConfig: 'cartConfig',
};

const _convertCartConfigToState = (cart, profile = null) => {
  return {
    userConfig: {
      name: profile?.name || cart.buyer_name || '',
      phone: profile?.phone || '', // phone can only be the same as profile
      email: profile?.email || cart.buyer_email || '',
      zip: profile?.zip || cart.buyer_zip || '',
      tel: profile?.tel || cart.buyer_tel || '',
      tel_ext: profile?.tel_ext || cart.buyer_tel_ext || '',
      address: profile?.address || cart.buyer_address || '',
      city: profile?.city || cart.buyer_city || '',
      district: profile?.district || cart.buyer_district || '',
    },
    deliveryConfig: {
      name: cart.receiver_name || '',
      phone: cart.receiver_phone || '',
      email: cart.receiver_email || '',
      zip: cart.receiver_zip || '',
      tel: cart.receiver_tel || '',
      tel_ext: cart.receiver_tel_ext || '',
      address: cart.receiver_address || '',
      city: cart.receiver_city || '',
      district: cart.receiver_district || '',
    },
    invoiceConfig: {
      invoice_type: '',
      invoice_subtype: '',
      // invoice_detail: '',

      citizen_personal_certificate_code:
        profile?.citizen_personal_certificate_code ||
        cart.citizen_personal_certificate_code ||
        '', //自然人憑證條碼(16碼)
      mobile_vehicle_code:
        profile?.mobile_vehicle_code || cart.mobile_vehicle_code || '', //手機載具(8碼)

      gui_number: cart.gui_number || '',
    },
    extraCartItemsConfig: {
      note: cart.note || '',
    },
    checkoutParams: {
      note: cart.order_note || '',
    },
  };
};

const _convertStateToCartConfig = (data) => {
  const {
    userConfig,
    deliveryConfig,
    invoiceConfig,
    extraCartItemsConfig,
    checkoutParams,
  } = data;
  return {
    buyer_name: userConfig.name,
    buyer_phone: userConfig.phone,
    buyer_email: userConfig.email,
    buyer_zip: userConfig.zip,
    buyer_tel: userConfig.tel,
    buyer_address: userConfig.address,
    buyer_city: userConfig.city,
    buyer_district: userConfig.district,
    buyer_tel_ext: userConfig.tel_ext,
    receiver_name: deliveryConfig.name,
    receiver_phone: deliveryConfig.phone,
    receiver_email: deliveryConfig.email,
    receiver_address: deliveryConfig.address,
    receiver_city: deliveryConfig.city,
    receiver_district: deliveryConfig.district,
    receiver_zip: deliveryConfig.zip,
    receiver_tel: deliveryConfig.tel,
    receiver_tel_ext: deliveryConfig.tel_ext,
    invoice_type: invoiceConfig.invoice_type,
    invoice_subtype: invoiceConfig.invoice_subtype,
    mobile_vehicle_code: invoiceConfig.mobile_vehicle_code,
    citizen_personal_certificate_code:
      invoiceConfig.citizen_personal_certificate_code,
    gui_number: invoiceConfig.gui_number,
    note: extraCartItemsConfig.note,
    order_note: checkoutParams.note,
  };
};

function CartPage(props) {
  const {
    pageContext: {homeData},
  } = props;
  const app = React.useContext(AppContext.Context);
  const [uiState, setUiState] = React.useState(UiState.cartItems);
  const [
    syncCartBuyerWithProfile,
    setSyncCartBuyerWithProfile,
  ] = React.useState(false);
  const [shouldDoValidate, setShouldDoValidate] = React.useState(false);
  const [config, setConfig] = React.useState({
    userConfig: {
      name: '',
      phone: '',
      email: '',
      zip: '',
      tel: '',
      tel_ext: '',
      address: '',
      city: '',
      district: '',
    },
    deliveryConfig: {
      name: '',
      phone: '',
      email: '',
      zip: '',
      tel: '',
      tel_ext: '',
      address: '',
      city: '',
      district: '',
    },
    invoiceConfig: {
      invoice_type: '',
      invoice_subtype: '',
      invoice_detail: '',

      citizen_personal_certificate_code: '', //自然人憑證條碼(16碼)
      mobile_vehicle_code: '', //手機載具(8碼)

      gui_number: '',
      is_invoice_agreement_confirm: false,
    },
    extraCartItemsConfig: {
      note: '',
    },
    checkoutParams: {
      note: '',
    },
  });
  const [loading, setLoading] = React.useState(false);
  const [extraUiData, setExtraUiData] = React.useState({
    is_invoice_agreement_confirm: false,
  });
  const [couponCode, setCouponCode] = React.useState('');
  const {cart, profile} = app;

  useViewed(() => {
    Gtag('event', 'view_cart', {
      currency: 'TWD',
      value: cart.total,
      items: cart.items.map((i) => ({
        item_id: i.product.id,
        item_name: i.product.name,
        spec: i.spec.item_id,
      })),
    });
  }, !cart);

  useEffect(() => {
    if (uiState === UiState.cartConfig) {
      Gtag('event', 'add_payment_info', {
        currency: 'TWD',
        value: cart.total,
        payment_type: 'cathay',
        payment_subtype: 'credit',
        items: cart.items.map((i) => ({
          item_id: i.product.id,
          item_name: i.product.name,
        })),
      });
      Gtag('event', 'add_shipping_info', {
        currency: 'TWD',
        value: cart.total,
        logistics_type: cart.logistic_type,
        items: cart.items.map((i) => ({
          item_id: i.product.id,
          item_name: i.product.name,
        })),
      });
    }
  }, [cart, uiState]);

  let _dataInUrl = React.useMemo(() => queryParams(props.location.search), [
    props.location.search,
  ]);

  React.useEffect(() => {
    if (INVALID_CART_ERROR_KEYS.indexOf(_dataInUrl.error) !== -1) {
      Ant.message.warn(
        '您的購物車商品已售完，系統已自動移除，感謝您的配合與體諒',
      );
    } else if (_dataInUrl.error) {
      let foundError = INVALID_CART_DATA_ERROR_KEYS[_dataInUrl.error] || null;
      if (foundError) {
        Ant.message.warn(foundError.hint);
      }
    }
  }, [_dataInUrl]);

  React.useEffect(() => {
    const _updateCartToGetNewPrice = async () => {
      AppActions.setLoading(true);
      let _data = _convertStateToCartConfig(config);
      let _cart = await app.actions.editCartConfig(_data);
      AppActions.setLoading(false);
    };

    let city = config.deliveryConfig.city;
    if (
      city &&
      INVALID_RECEIVER_ADDRESS_CITY.indexOf(city) === -1 &&
      config.deliveryConfig.city !== cart.receiver_city
    ) {
      _updateCartToGetNewPrice();
    }
  }, [config]);

  React.useEffect(() => {
    if (cart) {
      let _updatedConfig = _convertCartConfigToState(cart, profile);
      setConfig(_updatedConfig);
    }
  }, [cart, profile]);

  const _isConfigValid = React.useCallback(
    (cart) => {
      let {userConfig, deliveryConfig, invoiceConfig} = config;

      // user config
      if (
        !isNotEmpty(userConfig.name) ||
        !isNotEmpty(userConfig.address) ||
        !isNotEmpty(userConfig.city) ||
        !isNotEmpty(userConfig.district) ||
        !isNotEmpty(userConfig.email) ||
        !isNotEmpty(userConfig.phone)
      ) {
        Ant.message.info('請填入必填欄位');
        return false;
      }
      if (!isEmail(userConfig.email)) {
        Ant.message.info('電子信箱格式錯誤');
        return false;
      }
      if (!isMobileNumber(userConfig.phone)) {
        Ant.message.info('手機格式錯誤');
        return false;
      }
      if (!isChineseName(userConfig.name)) {
        Ant.message.info('請填寫正確的姓名');
        return false;
      }

      //delivery config
      if (cart.logistic_type !== LOGISTICE_TYPE.selfPick) {
        if (
          !isNotEmpty(deliveryConfig.name) ||
          !isNotEmpty(deliveryConfig.address) ||
          !isNotEmpty(deliveryConfig.city) ||
          !isNotEmpty(deliveryConfig.district) ||
          !isNotEmpty(deliveryConfig.email) ||
          !isNotEmpty(deliveryConfig.phone)
        ) {
          Ant.message.info('請填入宅配收件人必填欄位');
          return false;
        }
        if (!isMobileNumber(deliveryConfig.phone)) {
          Ant.message.info('宅配收件人手機格式錯誤');
          return false;
        }
        if (!isEmail(deliveryConfig.email)) {
          Ant.message.info('基本資料電子信箱格式錯誤');
          return false;
        }
        if (!isChineseName(deliveryConfig.name)) {
          Ant.message.info('請填寫正確的收件人姓名');
          return false;
        }
        if (INVALID_RECEIVER_ADDRESS_CITY.indexOf(deliveryConfig.city) !== -1) {
          Ant.message.info('收件地址目前不提供宅配服務');
          return false;
        }
      }

      if (!isNotEmpty(invoiceConfig.invoice_type)) {
        Ant.message.info('請確認發票填寫欄位');
        return false;
      }

      // invoice config
      if (invoiceConfig.invoice_type === INVOICE_TYPE.twoCopies) {
        if (!isNotEmpty(invoiceConfig.invoice_subtype)) {
          Ant.message.info('請確認發票填寫欄位');
          return false;
        }

        if (invoiceConfig.invoice_subtype === INVOICE_SUBTYPE.citizenCarrier) {
          if (
            !isPersonCertificatedCode(
              invoiceConfig.citizen_personal_certificate_code,
            )
          ) {
            Ant.message.info('請確認發票填寫欄位');
            return false;
          }
        } else if (
          invoiceConfig.invoice_subtype === INVOICE_SUBTYPE.mobileCarrier
        ) {
          if (!isMobileVehicleCode(invoiceConfig.mobile_vehicle_code)) {
            Ant.message.info('請確認發票填寫欄位');
            return false;
          }
        }
      } else if (invoiceConfig.invoice_type === INVOICE_TYPE.threeCopies) {
        if (!isCompanyId(invoiceConfig.gui_number)) {
          Ant.message.info('請確認發票填寫欄位');
          return false;
        }
      }

      if (!extraUiData.is_invoice_agreement_confirm) {
        Ant.message.info(
          '請勾選發票同意由法朋烘焙坊代爲處理電子發票及銷退回證明單',
        );
        return false;
      }
      return true;
    },
    [config, extraUiData],
  );

  const _allowGoNext = React.useMemo(() => cart && cart.total > 0, [cart]);

  const _onRemoveCartItem = React.useCallback(
    async (idx) => {
      AppActions.setLoading(true);
      try {
        const target = cart.items.find((_i, index) => index === idx);
        Gtag('event', 'remove_from_cart', {
          currency: 'TWD',
          value: target.amount,
          items: [
            {
              item_id: target.product.id,
              item_name: target.product.name,
            },
          ],
        });
        await app.actions.removeItemFromCart(idx);
      } catch (err) {
        //
        Ant.message.error('無法刪除商品！請稍候再試！');
      }
      AppActions.setLoading(false);
    },
    [app.actions, cart],
  );

  const _onCheckout = React.useCallback(async () => {
    setShouldDoValidate(true);
    if (!_isConfigValid(cart)) {
      return;
    }

    if (!profile.phone && config.userConfig.phone) {
      console.log('authorized user checkout');
      // phone hasn't verified
      const _onVerifyPhoneSuccess = async () => {
        AppActions.setModal(null);
        AppActions.setLoading(true);
        let _profile = await app.actions.refreshProfile();
        let _data = _convertStateToCartConfig({
          ...config,
          userConfig: {
            ...config.userConfig,
            phone: _profile.phone,
          },
        });
        let _cart = await app.actions.editCartConfig(_data);
        AppActions.setLoading(false);
        Ant.message.success('手機已驗證，請繼續付款流程');
      };
      AppActions.setModal(
        <PhoneVerifyModal
          defaultPhone={config.userConfig.phone}
          onSuccess={_onVerifyPhoneSuccess}
          successResultText="手機已驗證，請繼續付款流程"
        />,
      );
      return;
    }

    AppActions.setLoading(true);
    try {
      let _updateConfig = {...config};

      if (
        _updateConfig.invoiceConfig.invoice_type === INVOICE_TYPE.threeCopies
      ) {
        // force invoice sub type to be memberCarrier when is threeCopies
        _updateConfig.invoiceConfig.invoice_subtype =
          INVOICE_SUBTYPE.memberCarrier;
      }

      let _data = _convertStateToCartConfig(_updateConfig);
      let _cart = await app.actions.editCartConfig(_data);
      if (!_isCartItemValid(_cart)) {
        Ant.message.error('購物車有商品超過購買限制！');
        throw 'invalid cart';
      }
      setShouldDoValidate(false);

      if (syncCartBuyerWithProfile) {
        try {
          let _updatedProfile = {
            ...profile,
            owner: profile.owner,
            phone: profile.phone,
            name: _cart.buyer_name,
            email: _cart.buyer_email,
            zip: _cart.buyer_zip,
            tel: _cart.buyer_tel,
            tel_ext: _cart.buyer_tel_ext,
            address: _cart.buyer_address,
            city: _cart.buyer_city,
            district: _cart.buyer_district,
            citizen_personal_certificate_code:
              _cart.citizen_personal_certificate_code ||
              profile.citizen_personal_certificate_code,
            mobile_vehicle_code:
              _cart.mobile_vehicle_code || profile.mobile_vehicle_code,
          };
          let _respProfile = await app.actions.updateProfile(_updatedProfile);
          Ant.message.success('會員資料已同步更新');
        } catch (err) {
          console.log('err', err);
          // fail to sync profile, ignore
        }
      }

      Gtag('event', 'begin_checkout', {
        currency: 'TWD',
        value: cart.total,
        items: cart.items.map((i) => ({
          item_id: i.product.id,
          item_name: i.product.name,
        })),
      });
      let checkoutUrl = await app.actions.getCheckoutUrl();
      if (couponCode) {
        checkoutUrl += `&coupon=${couponCode}`;
      }
      window.open(checkoutUrl, '_self');
    } catch (err) {
      //
      console.log('error:', err);
    }
    AppActions.setLoading(false);
  }, [
    config,
    cart,
    app.actions,
    syncCartBuyerWithProfile,
    profile,
    extraUiData,
  ]);

  const _isCartItemValid = React.useCallback((cart) => {
    // FIXME: BUY_LIMIT for hard code buy-limit
    // aggregate same items
    let productInCart = {};
    for (let item of cart.items) {
      if (!productInCart[item.product.id]) {
        productInCart[item.product.id] = [item];
      } else {
        productInCart[item.product.id].push(item);
      }
    }

    for (let key of Object.keys(productInCart)) {
      if (PRODUCT_BUY_LIMIT[key]) {
        let _totoalProductCount = productInCart[key].reduce(
          (acc, curr, idx) => acc + curr.config.qty,
          0,
        );
        if (_totoalProductCount > PRODUCT_BUY_LIMIT[key].limit) {
          console.log(
            'invalid!',
            _totoalProductCount,
            PRODUCT_BUY_LIMIT[key].limit,
          );
          return false;
        }
      }

      // some special product has hardcoded-buy-time
      if (PRODUCT_ALLOW_BUY_TIME[key]) {
        let startTime = PRODUCT_ALLOW_BUY_TIME[key].start;
        let endTime = PRODUCT_ALLOW_BUY_TIME[key].end;
        let now = moment();
        if (startTime && now < moment(startTime)) {
          // Ant.message.warn("此商品尚未開放購買")
          return false;
        }
        if (endTime && now > moment(endTime)) {
          // Ant.message.warn("此商品已不開放購買")
          return false;
        }
      }
    }
    return true;
  }, []);

  const _onGoNextClick = React.useCallback(async () => {
    AppActions.setLoading(true);
    let _data = _convertStateToCartConfig(config);
    let _cart = await app.actions.editCartConfig(_data);
    // if cart item is invalid, BE will remove the item, then cart items maybe 0
    if (_cart.items.length === 0) {
      AppActions.setLoading(false);
      return;
    }
    if (!_isCartItemValid(_cart)) {
      Ant.message.error('購物車有商品超過購買限制或購買時間！');
    } else {
      setUiState(UiState.cartConfig);
      setCouponCode('');
    }
    AppActions.setLoading(false);
  }, [config]);

  const _onGoBackClick = React.useCallback(() => {
    if (uiState === UiState.cartItems) {
      navigate(URL_PATH.productList);
    } else if (uiState === UiState.cartConfig) {
      setUiState(UiState.cartItems);
    }
  }, [uiState]);

  const showCartHint = React.useMemo(() => {
    return homeData?.show_cart_hint || false;
  }, [homeData]);

  if (!cart) {
    return <Wrapper></Wrapper>;
  }

  if (uiState === UiState.cartConfig) {
    return (
      <div style={{backgroundColor: '#fff', flex: 1}}>
        <Wrapper>
          <div className="header">
            <Ant.Steps className="rev-steps" current={1}>
              {steps.map((step) => (
                <Ant.Steps.Step key={step} title={step} />
              ))}
            </Ant.Steps>
          </div>
          <div className="content cart-config">
            <Cart cart={cart} />
            <div className="price-row">
              <div className="placeholder" style={{flex: 1}}></div>
              <div style={{flex: 1}}>
                <TotalItemAmountForm
                  cart={cart}
                  couponCode={couponCode}
                  setCouponCode={setCouponCode}
                  isCheckoutFlow
                />
              </div>
            </div>
            <div className="form">
              <UserInfoForm
                config={config.userConfig}
                setConfig={(config) =>
                  setConfig((_config) => ({..._config, userConfig: config}))
                }
                isPhoneValidated={!!profile?.phone}
                doValidate={shouldDoValidate}
                syncProfileData={syncCartBuyerWithProfile}
                setSyncProfileData={setSyncCartBuyerWithProfile}
              />
            </div>
            {cart.logistic_type !== LOGISTICE_TYPE.selfPick && (
              <div className="form">
                <DeliveryForm
                  userConfig={config.userConfig} // for copy userConfig to receiver config feature
                  config={config.deliveryConfig}
                  setConfig={(config) =>
                    setConfig((_config) => ({
                      ..._config,
                      deliveryConfig: config,
                    }))
                  }
                  doValidate={shouldDoValidate}
                />
              </div>
            )}
            <div className="form">
              <InvoiceForm
                config={config.invoiceConfig}
                setConfig={(config) =>
                  setConfig((_config) => ({..._config, invoiceConfig: config}))
                }
                doValidate={shouldDoValidate}
                extraUiData={extraUiData}
                setExtraUiData={(extra) =>
                  setExtraUiData((_extra) => ({..._extra, ...extra}))
                }
              />
            </div>

            <div className="form">
              <PaymentForm />
            </div>
            <div className="form">
              <OrderNoteForm
                config={config.checkoutParams}
                setConfig={(config) =>
                  setConfig((_config) => ({..._config, checkoutParams: config}))
                }
              />
            </div>
          </div>
          <div className="footer">
            <Button
              size={'small'}
              type={'secondary'}
              style={{width: 122}}
              onClick={_onGoBackClick}>
              {'上一步'}
            </Button>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
              }}>
              <Button
                size={'small'}
                style={{width: 122}}
                onClick={_onCheckout}
                loading={loading}
                disabled={loading}
                shadow>
                {profile && profile.phone ? '前往付款' : '驗證並付款'}
              </Button>

              {!profile && (
                <p className="phone-validate-hint" style={{marginTop: 5}}>
                  您的手機尚未驗證，將進行註冊及驗證程序後繼續付款
                </p>
              )}

              {profile && !profile.phone && (
                <p className="phone-validate-hint" style={{marginTop: 5}}>
                  您的手機尚未驗證，將進行驗證程序後繼續付款
                </p>
              )}
            </div>
          </div>
        </Wrapper>
      </div>
    );
  }

  return (
    <div style={{backgroundColor: '#fff', flex: 1}}>
      <Wrapper>
        {showCartHint && homeData.cart_hint_image && (
          <img
            className="cart-hint-image"
            src={homeData.cart_hint_image}
            alt="cart-hint-image"
          />
        )}
        {showCartHint && homeData.cart_hint && (
          <div className="cart-hint">{homeData.cart_hint}</div>
        )}
        <div className="header">
          <Ant.Steps className="rev-steps" current={0}>
            {steps.map((step) => (
              <Ant.Steps.Step key={step} title={step} />
            ))}
          </Ant.Steps>
        </div>

        <div className="content cart">
          <Cart cart={cart} onRemoveCartItem={_onRemoveCartItem} editable />
          <div className="note">
            <Ant.Input.TextArea
              style={{flex: 1, height: 77}}
              value={config.extraCartItemsConfig.note}
              placeholder="請輸入訂單備註，若有疑問可洽LINE@真人客服協助"
              onChange={(e) =>
                setConfig({
                  ...config,
                  extraCartItemsConfig: {note: e.target.value},
                })
              }
            />
          </div>
        </div>

        <div className="footer">
          <Button
            size={'small'}
            type={'secondary'}
            style={{width: 122}}
            onClick={_onGoBackClick}>
            繼續購物
          </Button>
          <Button
            size={'small'}
            style={{width: 122}}
            disabled={!_allowGoNext}
            onClick={_onGoNextClick}
            shadow>
            下一步
          </Button>
        </div>
        <div className="footer-hint">
          <p>
            提醒您，在按下「下一步」前，請再次確認商品口味、數量、取貨日/配送日、金額是否正確。
          </p>
          <p>溫馨提醒：選擇日期以台灣台北時間為主，請海外訂購下單客戶注意</p>
        </div>
      </Wrapper>
    </div>
  );
}

const Wrapper = styled.div`
  max-width: var(--content-max-width);
  padding: 0px var(--content-padding);
  margin: 0 auto;
  background-color: #fff;
  flex: 1;
  width: 100%;
  padding-top: 45px;
  font-family: 'Noto Sans TC';

  & > .cart-hint-image {
    width: 100%;
    margin-bottom: 10px;
  }
  & > .cart-hint {
    padding: 20px;
    border: 1px solid var(--color-glod);
    background-color: var(--color-glod-2);
    border-radius: 2px;
    font-size: 14px;
    width: 100%;
    text-align: center;
    white-space: pre-wrap;
    margin-bottom: 40px;
  }

  & > .header {
    & > .rev-steps {
      max-width: 800px;
      margin: 0 auto;
      margin-bottom: 30px;
    }
  }

  & > .content.cart {
    & > .note {
      margin-top: 20px;
    }
  }

  & > .content.cart-config {
    & > .price-row {
      display: flex;
      margin-top: 40px;

      @media screen and (max-width: ${BREAK_POINTS.mobile}px) {
        & > .placeholder {
          display: none;
        }
      }
    }

    & > .form {
      margin-top: 40px;
    }
  }

  & > .footer {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
  }

  & > .footer-hint {
    color: var(--color-red);
    text-align: right;
    margin-top: 5px;
  }
`;

export default CartPage;
